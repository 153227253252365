<template>
    <div class="candlestick">
        <div class="citys tabs">
            <div v-for="item in citys" :key="item.value" :class="['city', { active: active == item.value }]"
                @click="handleSelectCity(item.value)">
                <span v-show="activeSwitch == 'k'">{{ item.name }}</span>
            </div>
        </div>
        <!-- 单项数据 -->
        <div v-if="false" class="form">
            <div class="top">
                <div class="open">
                    <span class="label">开盘价：</span>
                    <span class="value">{{ form.open || "-" }}</span>
                </div>
                <div class="close">
                    <span class="label">收盘价：</span>
                    <span class="value">{{ form.close || "-" }}</span>
                </div>
                <div class="low">
                    <span class="label">最低价：</span>
                    <span class="value">{{ form.low || "-" }}</span>
                </div>
                <div class="high">
                    <span class="label">最高价：</span>
                    <span class="value">{{ form.high || "-" }}</span>
                </div>
            </div>
            <div class="bottom">
                <div class="amount">
                    <span class="label">成交量：</span>
                    <span class="value">{{ form.open || "-" }}</span>
                </div>
                <div class="total">
                    <span class="label">成交额：</span>
                    <span class="value">{{ form.total || "-" }}</span>
                </div>
            </div>
        </div>
        <!-- k线 走势 -->
        <div class="switchs tabs">
            <span :class="['k', 'switch', { active: activeSwitch == 'k' }]" @click="handleSwitch('k')">k线</span>
            <span :class="['trend', 'switch', { active: activeSwitch == 'trend' }]" @click="handleSwitch('trend')">走势</span>
        </div>

        <div class="echart-wrapper">
            <div class="echart-ref" ref="echart_ref"></div>
            <div class="echart-ref" ref="echart_ref_combine"></div>
        </div>

        <!-- 走势表格 -->
        <el-table :data="tableData" stripe style="width: 100%" v-if="tableShow">
            <el-table-column prop="current_dates" label="日期" width="125">
            </el-table-column>
            <el-table-column prop="china_prices" label="全国" width="125" align="right">
            </el-table-column>
            <el-table-column prop="hubei_prices" label="湖北" width="125" align="right">
            </el-table-column>
            <el-table-column prop="shenzhen_prices" label="深圳" width="125" align="right">
            </el-table-column>
            <el-table-column prop="guangdong_prices" label="广东" width="125" align="right">
            </el-table-column>
            <el-table-column prop="tianjin_prices" label="天津" width="125" align="right">
            </el-table-column>
            <el-table-column prop="shanghai_prices" label="上海" width="125" align="right">
            </el-table-column>
            <el-table-column prop="beijing_prices" label="北京" width="125" align="right">
            </el-table-column>
            <el-table-column prop="chongqin_prices" label="重庆" width="125" align="right">
            </el-table-column>
        </el-table>
        <!-- k线表格 -->
        <el-table :data="newlist" stripe style="width: 100%" v-else>
            <el-table-column prop="newdate_list" label="日期" width="140">
            </el-table-column>
            <el-table-column prop="newprice_list[0]" label="开盘价" width="170" align="right">
            </el-table-column>
            <el-table-column prop="newprice_list[1]" label="收盘价" width="170" align="right">
            </el-table-column>
            <el-table-column prop="newprice_list[2]" label="最低价" width="170" align="right">
            </el-table-column>
            <el-table-column prop="newprice_list[3]" label="最高价" width="170" align="right">
            </el-table-column>
            <el-table-column prop="newamount_list" label="成交量" width="170" align="right">
            </el-table-column>
            <el-table-column prop="newtotal_list" label="成交额" width="170" align="right">
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import * as echarts from "echarts";
import { toFixed } from "../../utils/util";
export default {
    name: "Candlestick",
    data() {
        return {
            newlist: [],
            newdate_list: [],
            newprice_list: [],
            newamount_list: [],
            newtotal_list: [],
            tableShow: true,
            tableData: [],
            citys: [
                { name: "全国", value: "china" },
                { name: "湖北", value: "hubei" },
                { name: "深圳", value: "shenzhen" },
                { name: "广东", value: "guangdong" },
                { name: "天津", value: "tianjin" },
                { name: "上海", value: "shanghai" },
                { name: "北京", value: "beijing" },
                { name: "重庆", value: "chongqin" },
                { name: "福建", value: "fujian" },
            ],
            active: "hubei",
            // k || trend
            activeSwitch: "k",
            ref: null,
            instance: null,
            combine_ref: null,
            combine_instance: null,
            options: null,
            combine_options: null,
            date_list: [],
            price_list: [],
            amount_list: [],
            total_list: [],
            resizing: null,
            form: {
                time: "今天",
                open: "",
                close: "",
                low: "",
                high: "",
                amount: "",
                total: "",
            },
            current_dates: [],
            china_prices: [],
            hubei_prices: [],
            shenzhen_prices: [],
            guangdong_prices: [],
            tianjin_prices: [],
            shanghai_prices: [],
            beijing_prices: [],
            chongqin_prices: [],
            fujian_prices: [],
        };
    },
    activated() {
        console.log("Candlestick, activated");
        if (this.$route.query.all) {
            this.active = "china";
        } else {
            this.active = "hubei";
        }
        // this.activeSwitch = 'trend';
        this.handleSwitch("trend");
        window.addEventListener("resize", this.handleResize);
    },
    deactivated() {
        window.removeEventListener("resize", this.handleResize);
        this.activeSwitch = "";
    },
    methods: {
        initInstance() {
            let ref = this.$refs.echart_ref;
            this.ref = ref instanceof Array ? ref[0] : ref;
            this.instance = echarts.init(this.ref, null, { renderer: "svg" });
            // let that = this;
            // this.instance.on("showTip", function (params) {
            //     // console.log('showTip', params.dataIndex);
            // });
            // this.instance.on("hideTip", function (params) {
            //     // console.log('hideTip');
            // });
        },
        initKOption() {
            let upColor = "#ec0000";
            // let upBorderColor = '#8A0000';
            let downColor = "#00da3c";
            // let downBorderColor = '#008F28';
            // let upColor = "rgb(255, 255, 255)";
            // let upBorderColor = 'red';
            // let downColor = "rgb(0, 128, 0)";
            // let downBorderColor = 'rgb(0, 128, 0)';
            let that = this;
            this.options = {
                // title: {
                //     text: '上证指数',
                //     left: 0
                // },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            show: false,
                        },
                        lineStyle: {
                            type: "solid",
                            // color: '#fff',
                        },
                        crossStyle: {
                            type: "solid",
                            // color: '#fff',
                        },
                    },
                    // position: function (pos, params, el, elRect, size) {
                    //     const obj = {
                    //         top: 60
                    //     };
                    //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    //     return obj;
                    // },
                    formatter: (params) => {
                        //修改鼠标划过显示为中文
                        // console.log('formatter, params', params);
                        // let ma5=params[1].data;//ma5的值
                        // let ma10=params[1].data;//ma10的值
                        // let ma20=params[1].data;//ma20的值
                        // let ma30=params[1].data;//ma30的值
                        // params = params[0];//开盘收盘最低最高数据汇总
                        let open = "-";
                        let close = "-";
                        let low = "-";
                        let high = "-";
                        // 成交量
                        let amount = "-";
                        let total = "-";
                        let time = that.date_list[params[0].dataIndex];
                        // for (let i = 0; i < params.length; i++){
                        //     if (params[i].seriesIndex == 0){
                        //         open = params[i].data[1];
                        //         close = params[i].data[2];
                        //         low = params[i].data[3];
                        //         high = params[i].data[4];
                        //     } else {
                        //         amount = params[i].data;
                        //     }
                        // }
                        open = that.price_list[params[0].dataIndex][0];
                        close = that.price_list[params[0].dataIndex][1];
                        low = that.price_list[params[0].dataIndex][2];
                        high = that.price_list[params[0].dataIndex][3];
                        amount = that.amount_list[params[0].dataIndex];
                        total = that.total_list[params[0].dataIndex];
                        // console.log('tooltip', params,params.map(item => item.name));

                        open =
                            open == "" || open == undefined || /'-'/.test(open)
                                ? "-"
                                : toFixed(open, 2);
                        close =
                            close == "" || close == undefined || /'-'/.test(close)
                                ? "-"
                                : toFixed(close, 2);
                        low =
                            low == "" || low == undefined || /'-'/.test(low)
                                ? "-"
                                : toFixed(low, 2);
                        high =
                            high == "" || high == undefined || /'-'/.test(high)
                                ? "-"
                                : toFixed(high, 2);
                        amount =
                            amount == "" || amount == undefined || /'-'/.test(amount)
                                ? "-"
                                : amount;
                        total =
                            total == "" || total == undefined || /'-'/.test(total)
                                ? "-"
                                : toFixed(total, 2);
                        // total =
                        //     !total || /-/.test(total) ? "-" : toFixed(parseFloat(total), 2);
                        // params[0].seriesName == "天津" &&
                        //     console.log("total", total, that.total_list[index]);

                        return (
                            // params.seriesName +
                            // "<br>" +
                            time +
                            "<br>" +
                            "开盘价: " +
                            open +
                            "<br>" +
                            "收盘价: " +
                            close +
                            "<br>" +
                            "最低价: " +
                            low +
                            "<br>" +
                            "最高价: " +
                            high +
                            "<br>" +
                            "成交量: " +
                            amount +
                            "<br>" +
                            "成交额: " +
                            total +
                            "<br>"
                        );
                    },
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                    label: {
                        backgroundColor: "#777",
                    },
                },
                toolbox: {
                    feature: {
                        dataZoom: {
                            show: false,
                            yAxisIndex: false,
                        },
                        brush: {
                            type: ["lineX", "clear"],
                        },
                    },
                },
                brush: {
                    xAxisIndex: "all",
                    brushLink: "all",
                    outOfBrush: {
                        colorAlpha: 0.1,
                    },
                },
                // visualMap: {
                //     show: false,
                //     seriesIndex: 1,
                //     // dimension: 3,
                //     pieces: [
                //         {
                //             value: 200,
                //             color: downColor
                //         },
                //         {
                //             value: -1,
                //             color: upColor
                //         }
                //     ]
                // },
                legend: {
                    data: [],
                    show: false,
                },
                grid: [
                    {
                        top: "5%",
                        left: 80,
                        right: 35,
                        // bottom: 40,
                        height: "52%",
                    },
                    {
                        top: "70%",
                        left: 80,
                        right: 35,
                        bottom: "12%",
                        // height: '16%'
                    },
                ],
                xAxis: [
                    {
                        type: "category",
                        data: [],
                        boundaryGap: false,
                        axisLine: { onZero: false },
                        splitLine: { show: false },
                        // inverse: true,
                        // min: "dataMin",
                        // max: "dataMax",
                        // axisPointer: {
                        //     z: 100
                        // }
                    },
                    {
                        type: "category",
                        data: [],
                        gridIndex: 1,
                        boundaryGap: false,
                        axisLine: { onZero: false },
                        splitLine: { show: false },
                        axisLabel: { show: false },
                        // inverse: true,
                    },
                ],
                yAxis: [
                    {
                        scale: true,
                        splitArea: {
                            show: false,
                        },
                        min: function (value) {
                            let temp = value.min;
                            return temp > 0.5 ? temp - 0.5 : 0;
                        },
                        max: function (value) {
                            let temp = value.max;
                            return temp + 0.5;
                        },
                    },
                    {
                        scale: true,
                        gridIndex: 1,
                        // splitNumber: 2,
                        axisLabel: { show: true },
                        axisLine: { show: false },
                        axisTick: { show: false },
                        splitLine: { show: false },
                    },
                ],
                dataZoom: [
                    {
                        type: "inside",
                        xAxisIndex: [0, 1],
                        startValue: 0,
                        endValue: 49,
                        // minValueSpan: 50,
                        // maxValueSpan: 50,
                    },
                    {
                        xAxisIndex: [0, 1],
                        type: "slider",
                        top: "90%",
                        startValue: 0,
                        endValue: 49,
                        // minValueSpan: 50,
                        // maxValueSpan: 50,
                    },
                ],
                series: [
                    {
                        name: "日K",
                        type: "candlestick",
                        data: [],
                        itemStyle: {
                            color: upColor,
                            color0: downColor,
                            borderColor: undefined,
                            borderColor0: undefined,
                            // borderWidth: 3
                        },
                    },
                    {
                        name: "成交量",
                        type: "bar",
                        data: [],
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            //   color: upColor,
                            //   color0: downColor,
                            normal: {
                                // barBorderRadius: [20, 20, 0, 0], // 加圆角 对应（顺时针左上，右上，右下，左下）
                                // 这里的color指的是每个颜色的回调函数
                                color: function (params) {
                                    // console.log("颜色参数", params); //这里我们有七个柱状图，七份数据，所以会打印7次。
                                    // console.log(that.instance.getOption());
                                    let data = that.options.series[0].data[params.dataIndex];
                                    // console.log('itemStyle', data, params.name);
                                    let open = data[0];
                                    let close = data[1];
                                    if (!open || !close || /-/.test(open) || /-/.test(close)) {
                                        return "gray";
                                    } else if (open > close) {
                                        return downColor;
                                    } else if (open < close) {
                                        return upColor;
                                    } else if (open == close) {
                                        return upColor;
                                    } else {
                                        return "gray";
                                    }
                                    // var colorArr = ["#baf", "#bfa", "#cde"];
                                    // // params.dataIndex指的是每个柱状图的索引下标 分别为0 1 2 3 4 5 6 7 8 9
                                    // return colorArr[params.dataIndex % colorArr.length];
                                },
                            },
                        },
                    },
                ],
            };
        },
        initTrendOption() {
            this.options = {
                // title: {
                //     text: '上证指数',
                //     left: 0
                // },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            show: false,
                        },
                        lineStyle: {
                            type: "solid",
                            // color: '#fff',
                        },
                        crossStyle: {
                            type: "solid",
                            // color: '#fff',
                        },
                    },
                    // position: function (pos, params, el, elRect, size) {
                    //     const obj = {
                    //         top: '10%'
                    //     };
                    //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    //     return obj;
                    // },
                    // valueFormatter: (value) => {
                    //     return ' 收盘价:' + (typeof value === 'number' && !isNaN(value) ? toFixed(value, 2) : '-')
                    // }
                    formatter: (params) => {
                        //修改鼠标划过显示为中文
                        // console.log('tooltip', params);
                        let Marker =
                            '<span style="display:inline-block;margin-right:6px;border-radius:10px;width:10px;height:10px;background-color:#color;"></span>';
                        let str = "";
                        for (let i = 0; i < params.length; i++) {
                            let sub_str = "";
                            let color = params[i].color;
                            let seriesName = params[i].seriesName;
                            let marker = Marker.replace("#color", color);
                            let value = params[i].data;
                            if (typeof value === "number" && !isNaN(value)) {
                                value = toFixed(value, 2) + "元";
                            } else {
                                value = "-";
                            }
                            if (!i) {
                                sub_str += params[i].name + "<br>";
                            }
                            sub_str += marker;
                            sub_str += seriesName;
                            sub_str += " 收盘价: " + value;
                            if (i !== params.length - 1) {
                                sub_str += "<br>";
                            }
                            str += sub_str;
                        }
                        // console.log('tooltip', params);
                        return str;
                    },
                },
                // toolbox: {
                //     feature: {
                //         dataZoom: {
                //             // show: false,
                //             // yAxisIndex: false
                //         },
                //         brush: {
                //             type: ['lineX', 'clear']
                //         }
                //     }
                // },
                // brush: {
                //     xAxisIndex: 'all',
                //     brushLink: 'all',
                //     outOfBrush: {
                //         colorAlpha: 0.1
                //     }
                // },
                legend: {
                    data: this.citys.map((item) => item.name),
                    // show: false,
                },
                grid: [
                    {
                        left: 80,
                        right: 35,
                        bottom: 40,
                        top: "10%",
                        height: "65%",
                    },
                ],
                xAxis: [
                    {
                        type: "category",
                        data: [],
                        boundaryGap: false,
                        axisLine: { onZero: false },
                        splitLine: { show: false },
                        // inverse: true,
                        // axisLabel: { show: false },
                    },
                ],
                yAxis: [
                    {
                        axisLabel: { show: true },
                        axisLine: { show: false },
                        axisTick: { show: false },
                        splitLine: { show: true },
                        splitArea: {
                            show: false,
                        },
                    },
                ],
                dataZoom: [
                    {
                        type: "inside",
                        // xAxisIndex: [0, 1],
                        startValue: 999790,
                        endValue: 999999,
                        // minValueSpan: 50,
                        // maxValueSpan: 50,
                    },
                    {
                        type: "slider",
                        startValue: 999790,
                        endValue: 999999,
                        top: "80%",
                        // minValueSpan: 50,
                        // maxValueSpan: 50,
                    },
                ],
                series: [
                    {
                        name: "全国",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "red",
                            shadowColor: "red",
                            shadowBlur: 15,
                            // '#5470c6',
                            // shadowOffsetX: 1,
                            // shadowOffsetY: 1
                        },
                    },
                    {
                        name: "湖北",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#91cc75",
                            shadowColor: "#91cc75",
                            shadowBlur: 15,
                        },
                    },
                    {
                        name: "深圳",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#fac858",
                            shadowColor: "#fac858",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "广东",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#ee6666",
                            shadowColor: "#ee6666",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "天津",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#73c0de",
                            shadowColor: "#73c0de",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "上海",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#3ba272",
                            shadowColor: "#3ba272",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "北京",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#fc8452",
                            shadowColor: "#fc8452",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "重庆",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#9a60b4",
                            shadowColor: "#9a60b4",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "福建",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#ea7ccc",
                            shadowColor: "#ea7ccc",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                ],
            };
        },
        getKEchartsData() {
            // http://192.168.23.15:8000/prices/getcarbonbusinesslist
            this.instance.showLoading({ text: "" });
            // this.combine_instance.showLoading();
            this.api
                .postFormAPISM(
                    {
                        city: this.active,
                        limit: 99999,
                        send_time: "",
                    },
                    "/prices/getcarbonbusinesslist"
                )
                .then((res) => {
                    // console.log('getEchartsData', res.data);
                    this.newprice_list = res.data.data.price_list.slice(0, 30);
                    this.newdate_list = res.data.data.date_list.slice(0, 30);
                    this.newamount_list = res.data.data.amount_list.slice(0, 30);
                    this.newtotal_list = res.data.data.total_list.slice(0, 30);
                    if (this.newlist.length == 0) {
                        for (let i = 0; i < 30; i++) {
                            let tmpe = {
                                newprice_list: { ...this.newprice_list[i] },
                                newamount_list: this.newamount_list[i],
                                newtotal_list: this.newtotal_list[i],
                                newdate_list: this.newdate_list[i],
                            };
                            this.newlist.push(tmpe);
                        }
                        console.log(this.newlist, "this.newlist");
                    } else {
                        this.newlist.splice(0, 30);
                        for (let i = 0; i < 30; i++) {
                            let tmpe = {
                                newprice_list: { ...this.newprice_list[i] },
                                newamount_list: this.newamount_list[i],
                                newtotal_list: this.newtotal_list[i],
                                newdate_list: this.newdate_list[i],
                            };
                            this.newlist.push(tmpe);
                        }
                        console.log(this.newlist, "如果");
                    }
                    // console.log(this.newlist,'this.newlist');
                    this.price_list = res.data.data.price_list.reverse();
                    this.date_list = res.data.data.date_list.reverse();
                    this.amount_list = res.data.data.amount_list.reverse();
                    this.total_list = res.data.data.total_list.reverse();

                    this.setKOption();
                })
                .catch((error) => {
                    console.log("getEchartsData, error", error);
                });
        },
        getTrendEchartsData() {
            // http://192.168.23.15:8000/prices/getcarbonpricetrend
            this.instance.showLoading({ text: "" });
            // this.combine_instance.showLoading();
            this.api
                .postFormAPISM(
                    {
                        limit: 999999,
                        send_time: "",
                    },
                    "/prices/getcarbonpricetrend"
                )
                .then((res) => {
                    console.log("getTrendEchartsData", res.data);
                    this.china_prices = res.data.data.china_prices.slice(0, 30);
                    this.hubei_prices = res.data.data.hubei_prices.slice(0, 30);
                    this.shenzhen_prices = res.data.data.shenzhen_prices.slice(0, 30);
                    this.guangdong_prices = res.data.data.guangdong_prices.slice(0, 30);
                    this.tianjin_prices = res.data.data.tianjin_prices.slice(0, 30);
                    this.shanghai_prices = res.data.data.shanghai_prices.slice(0, 30);
                    this.chongqin_prices = res.data.data.chongqin_prices.slice(0, 30);
                    this.current_dates = res.data.data.current_dates.slice(0, 30);
                    this.beijing_prices = res.data.data.beijing_prices.slice(0, 30);

                    if (this.tableData.length == 0) {
                        for (let i = 0; i < 30; i++) {
                            let tmpe = {
                                china_prices: this.china_prices[i],
                                hubei_prices: this.hubei_prices[i],
                                shenzhen_prices: this.shenzhen_prices[i],
                                guangdong_prices: this.guangdong_prices[i],
                                tianjin_prices: this.tianjin_prices[i],
                                shanghai_prices: this.shanghai_prices[i],
                                chongqin_prices: this.chongqin_prices[i],
                                current_dates: this.current_dates[i],
                                beijing_prices: this.beijing_prices[i],
                            };
                            this.tableData.push(tmpe);
                        }
                        this.tableData.forEach((e) => {
                            // console.log(e,'e');
                            if (e.china_prices == "None") {
                                e.china_prices = "-";
                            }
                            if (e.hubei_prices == "None") {
                                e.hubei_prices = "-";
                            }
                            if (e.shenzhen_prices == "None") {
                                e.shenzhen_prices = "-";
                            }
                            if (e.guangdong_prices == "None") {
                                e.guangdong_prices = "-";
                            }
                            if (e.tianjin_prices == "None") {
                                e.tianjin_prices = "-";
                            }
                            if (e.shanghai_prices == "None") {
                                e.shanghai_prices = "-";
                            }
                            if (e.chongqin_prices == "None") {
                                e.chongqin_prices = "-";
                            }
                            if (e.beijing_prices == "None") {
                                e.beijing_prices = "-";
                            }
                        });
                    }
                    console.log(this.tableData, "this.tableData");
                    let data = res.data.data;
                    let data_keys = Object.keys(data);
                    for (let i = 0; i < data_keys.length; i++) {
                        let temp = data[data_keys[i]].map((item) => {
                            if (
                                !item ||
                                item == "" ||
                                item == undefined ||
                                /^(-+|none)$/i.test(item)
                            ) {
                                return null;
                            } else {
                                return data_keys[i] == "current_dates"
                                    ? item
                                    : parseFloat(item);
                            }
                        });
                        this[data_keys[i]] = temp.reverse();
                    }
                    this.setTrendOption();
                })
                .catch((error) => {
                    console.log("getTrendEchartsData, error", error);
                });
        },
        setKOption() {
            this.options.dataZoom[0].startValue = this.date_list.length - 1;
            this.options.dataZoom[0].endValue = this.date_list.length - 50;
            this.options.dataZoom[1].startValue = this.date_list.length - 1;
            this.options.dataZoom[1].endValue = this.date_list.length - 50;
            //k线图
            let series_data = this.price_list.map((item) => {
                return item.map((Item) =>
                    /-/.test(Item) || !Item ? "-" : toFixed(parseFloat(Item), 2)
                );
            });
            // console.log('series_data', series_data);
            this.options.series[0].data = series_data;

            let series_data_amount = this.amount_list.map((item) => {
                return /-/.test(item) || !item ? "-" : toFixed(item.replace(",", ""));
            });
            this.options.series[1].data = series_data_amount;
            let city_name = this.citys[
                this.citys.findIndex((item) => item.value == this.active)
            ].name;
            // this.options.series[0].name = city_name;
            // this.options.series[0].name = city_name;
            // this.options.legend.data = [city_name];
            this.options.xAxis[0].data = this.date_list;
            this.options.xAxis[1].data = this.date_list;
            // console.log('ref', this.instance);
            this.instance.setOption(this.options);
            this.instance.hideLoading();

            //组合图
        },
        setTrendOption() {
            this.options.xAxis[0].data = this.current_dates;
            for (let i = 0; i < this.citys.length; i++) {
                this.options.series[i].data = this[this.citys[i].value + "_prices"];
                // console.log('setTrendOption', this.citys[i].value ,this.options.series[i].data);
            }
            console.log("setTrendOption", this.options);
            this.instance.hideLoading();
            this.instance.setOption(this.options);
        },
        handleSelectCity(active) {
            if (this.activeSwitch != "k") return;
            this.active = active;
            this.initKOption();
            this.getKEchartsData();
        },
        handleSwitch(activeSwitch) {
            if (this.activeSwitch == activeSwitch) return;
            console.log("handleSwitch", activeSwitch);
            this.activeSwitch = activeSwitch;
            this.instance && this.instance.dispose();
            this.initInstance();
            if (this.activeSwitch == "k") {
                this.tableShow = false;
                this.initKOption();
                this.getKEchartsData();
                // console.log(this.form,'form');
            } else {
                this.tableShow = true;
                this.initTrendOption();
                this.getTrendEchartsData();
            }
        },
        handleResize() {
            this.resizing && window.clearTimeout(this.resizing);
            this.resizing = setTimeout(() => {
                console.log("handleResize");
                this.instance && this.instance.resize && this.instance.resize();
                this.resizing = null;
            }, 500);
        },
    },
};
</script>
<style lang="less" scoped>
.candlestick {
    flex-grow: 1;
    width: 1200px;
    margin: auto;
    padding: 20px 10px 0;
    display: flex;
    flex-flow: column nowrap;

    &>.tabs {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex-shrink: 0;

        &.citys {
            height: 19px;
        }

        &>.city,
        &>.switch {
            padding: 0 10px;
            cursor: pointer;
            font-size: 14px;
            width: 50px;

            &.active {
                color: #ff2832;
            }

            &:hover {
                color: #ff2832;
            }
        }

        &>.switch {
            padding: 10px 10px;
        }
    }

    &>.echart-wrapper {
        flex-grow: 1;
        min-height: 700px;
        max-height: 700px;
        overflow: hidden;
        // display: flex;
        // flex-flow: column nowrap;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-gap: 20px;

        &>.echart-ref {

            //   flex-grow: 1;
            //   height: 50%;
            &>div {
                &:first-child {
                    height: 100%;
                }
            }
        }
    }

    &>.form {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
        font-size: 14px;

        &>div {
            // width: 50%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;

            &>div {
                width: 130px;
            }
        }
    }
}
</style>
